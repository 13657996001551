import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { useTranslation } from 'react-i18next';
import "./App.css";
import avatar from './assets/avatar.png';
import './i18n';
import emailjs from 'emailjs-com';

gsap.registerPlugin(MotionPathPlugin);

function App() {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: '', type: 'success' });
  const particlesRef = useRef(null);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'ja' : 'en';
    i18n.changeLanguage(newLang);
  };

  const languageToggleText = i18n.language === 'en' ? '日本語' : 'English';

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs.init("K4vjke5EFkI4Nl8yY");
    emailjs.sendForm('service_kdpnvkc', 'template_lm83xh5', event.target)
    .then((result) => {
      console.log('Email successfully sent!');
      setToast({ visible: true, message: t('messageSent'), type: 'success' });
      setTimeout(() => setToast({ visible: false, message: '', type: '' }), 3000);
      handleCloseModal();
      document.querySelector('input[name="email"]').value = '';
      document.querySelector('textarea[name="message"]').value = '';
    }, (error) => {
      console.log('Failed to send email:', error.text);
      setToast({ visible: true, message: t('messageFailed'), type: 'error' });
      setTimeout(() => setToast({ visible: false, message: '', type: '' }), 3000);
    });
  };

  useEffect(() => {
    const colors = ["#f4ee42", "#e4f442", "#c7f442", "#a9f442", "#8cf442"];
    const particles = particlesRef.current;

    for (let i = 0; i < 75; i++) {
      let div = document.createElement("div");
      div.className = "particle";
      div.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
      particles.appendChild(div);
      const animateParticle = () => {
        gsap.fromTo(
          div,
          {
            x: () => Math.random() * window.innerWidth,
            y: () => Math.random() * window.innerHeight,
            autoAlpha: 0,
            scale: 0.5,
          },
          {
            duration: () => Math.random() * 6 + 4,
            motionPath: {
              path: createRandomPath(),
              align: "self",
              alignOrigin: [0.5, 0.5],
              autoRotate: true,
            },
            scale: () => Math.random() * 0.75 + 0.25,
            autoAlpha: () => Math.random() * 0.5 + 0.5,
            onComplete: animateParticle,
            ease: "power1.inOut",
          }
        );
      };

      animateParticle();
    }

    function createRandomPath() {
      const path = [
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
        { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
      ];
      return path;
    }
  }, []);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="App">
      <button className="locale-select" onClick={toggleLanguage}>
        {languageToggleText}
      </button>
      <div className="particles" ref={particlesRef}></div>
      <div className="profile-card">
        <div className="profile-info">
        <img src={avatar} alt="Profile" className="profile-image" />
          <div className="profile-text">
            <h1 className={i18n.language === 'ja' ? 'japanese' : ''}>{t('title')}</h1>
            <p>{t('intro')}</p>
          </div>
        </div>
        <button className="cta-button" onClick={handleOpenModal}>{t('contactMe')}</button>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>&times;</span>
            <h2>{t('contactHeader')}</h2>
            <form onSubmit={handleSubmit}>
              <input type="email" name="email" placeholder={t('emailPlaceholder')} required />
              <textarea name="message" placeholder={t('messagePlaceholder')} required></textarea>
              <button type="submit">{t('send')}</button>
            </form>
          </div>
        </div>
      )}
      {toast.visible && (
        <div className={`message-toast ${toast.type}`}>
          {toast.message}
        </div>
      )}
    </div>
  );
}

export default App;
